class GlassesQuery {
  constructor() {
    this.token = sessionStorage.getItem("jwt");
    this.baseURL = "https://dryneatdirty.com/api/api";
  }

  async getData(page, size) {
    try {
      const response = await fetch(
        `${this.baseURL}/glasses?page=${page}&size=${size}`,
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching glasses:", error);
      throw error;
    }
  }
}

export default GlassesQuery;
