import React from "react";
import { Card } from "antd";
import "../style.scss";

const Glass = ({ id, name, description, image }) => {
  return (
    <Card title={name} bordered={false} className="antd-card">
      <ul>
        <li>ID: {id}</li>
        <li>Description: {description}</li>
        <li>Image: {image}</li>
      </ul>
    </Card>
  );
};

export default Glass;
