import React, { useRef, useEffect, useContext } from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import "../css/LoginBar.scss"; // Import the scss file

function LoginBar() {
  const headerRef = useRef(null);
  const { jwt, setJwt } = useContext(AuthContext);

  useEffect(() => {
    if (headerRef.current) {
      const headerHeight = headerRef.current.offsetHeight;
      document.documentElement.style.setProperty(
        "--header-height",
        `${headerHeight}px`
      );
    }
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      setJwt(sessionStorage.getItem("jwt"));
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <div className="login-bar" ref={headerRef}>
      {jwt ? (
        <Button
          className="login-button-clicked"
          onClick={() => {
            sessionStorage.removeItem("jwt");
            setJwt(null);
          }}
        >
          Log Out
        </Button>
      ) : (
        <Link to="/login">
          <Button className="login-button">Log In</Button>
        </Link>
      )}
    </div>
  );
}

export default LoginBar;
