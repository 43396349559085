import React from "react";
import { Card } from "antd";
import { Link } from "react-router-dom";
import "../style.scss";

const Drink = ({
  id,
  name,
  createdByUserId,
  drinkCategoryId,
  glassId,
  alcoholic,
  description,
  instructions,
  drinkIngredients,
}) => {
  return (
    <Card
      title={<Link to={`/drinks/${id}`}>{name}</Link>}
      bordered={false}
      className="antd-card"
    >
      <h2>Ingredients:</h2>
      <ul>
        {drinkIngredients.map((ingredient) => (
          <li key={ingredient.id}>
            {ingredient.quantity}{" "}
            {ingredient.unit.name === "count(s)"
              ? ""
              : ingredient.quantity === "1"
              ? ingredient.unit.unit_name_singular
              : ingredient.unit.unit_name_plural}{" "}
            {ingredient.quantity > 1
              ? ingredient.ingredient.ingredientNamePlural
              : ingredient.ingredient.ingredientNameSingular}{" "}
            {ingredient.ingredientOptional ? "(optional)" : ""}
          </li>
        ))}
      </ul>
      <h3>Other Info:</h3>
      <ul>
        <li>Drink ID: {id}</li>
        <li>Drink Category ID: {drinkCategoryId}</li>
        <li>User ID: {createdByUserId}</li>
        <li>Glass ID: {glassId}</li>
        <li>Alcoholic: {alcoholic}</li>
        <li>Description: {description}</li>
        <li>Instructions: {instructions}</li>
      </ul>
    </Card>
  );
};

export default Drink;
