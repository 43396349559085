import React from "react";
import { Card } from "antd";
import "../style.scss";

const Ingredient = ({
  id,
  name,
  ingredientNameSingular,
  ingredientNamePlural,
  ingredientCategoryId,
  description,
  ingredientSubcategoryId,
}) => {
  return (
    <Card title={name} bordered={false} className="antd-card">
      <ul>
        <li>ID: {id}</li>
        <li>Ingredient Category ID: {ingredientCategoryId}</li>
        <li>Ingredient Name Singular: {ingredientNameSingular}</li>
        <li>Ingredient Name Plural: {ingredientNamePlural}</li>
        <li>Description: {description}</li>
        <li>Ingredient Subcategory ID: {ingredientSubcategoryId}</li>
      </ul>
    </Card>
  );
};

export default Ingredient;
