import React, { useRef, useEffect } from "react";
import "../css/ApplicationFooter.scss"; // Import the SCSS file

function ApplicationFooter() {
  const footerRef = useRef(null);
  let footerHeightPx;

  useEffect(() => {
    if (footerRef.current) {
      const footerHeight = footerRef.current.offsetHeight;
      footerHeightPx = `${footerHeight}px`;
      document.documentElement.style.setProperty(
        "--footer-height",
        footerHeightPx
      );
    }
  }, []);

  return (
    <footer className="application-footer" ref={footerRef}>
      Copyright 2024 Adam Goss
    </footer>
  );
}

export default ApplicationFooter;
