import React from "react";
import { Card } from "antd";
import "../style.scss";

const Unit = ({ id, unitCategoryId, name, description }) => {
  return (
    <Card title={name} bordered={false} className="antd-card">
      <ul>
        <li>ID: {id}</li>
        <li>Unit Category ID: {unitCategoryId}</li>
        <li>Description: {description}</li>
      </ul>
    </Card>
  );
};

export default Unit;
